<template>
  <div class="pagination">
    <a class="preview" @click="changePage(false)" href="javascript:" />
    <span v-if="currentPage > 3">...</span>
    <a
      @click="changePage(item)"
      href="javascript:"
      :class="{ active: currentPage === item }"
      v-for="item in list"
      :key="item"
      >{{ item }}
    </a>
    <span v-if="currentPage < pages - 2">...</span>
    <a
      class="next"
      @click="changePage(true)"
      href="javascript:"
      :class="{ disabled: currentPage === pages }"
    />
  </div>
</template>
<script>
import { computed, ref } from "vue";

export default {
  name: "Pagination",
  props: {
    //数据总条数
    total: {
      type: Number,
      default: 80,
    },
    //每页数据条数
    pagesize: {
      type: Number,
      default: 5,
    },
    // 默认初始页码
    // page: {
    //   type: Number,
    //   default: 1
    // }
  },
  setup(props, { emit, attrs }) {
    // attrs表示父组件传递的属性，但是props没有接收的属性，这种属性不是响应式的  attrs接收父组件传递的当前页

    // 总页数
    const pages = computed(() => Math.ceil(props.total / props.pagesize));
    // 当前页码
    // console.log(attrs.page)
    // 如果父组件没有传递档当前页码，默认是第一页
    const currentPage = ref(attrs.page || 1);
    // 动态计算页码列表
    const list = computed(() => {
      // 当父组件传递total的值发生变化时，计算属性会重新计算
      let pages = computed(() => Math.ceil(props.total / props.pagesize));
      const result = [];
      // 总页码小于等于5；大于5
      if (pages.value <= 5) {
        // 总页码小于等于5的情况
        for (let i = 1; i <= pages.value; i++) {
          result.push(i);
        }
      } else {
        // 总页码大于5
        if (currentPage.value <= 2) {
          // 左侧临界值
          for (let i = 1; i <= 5; i++) {
            result.push(i);
          }
        } else if (currentPage.value >= pages.value - 1) {
          // 右侧临界值
          for (let i = pages.value - 4; i <= pages.value; i++) {
            result.push(i);
          }
        } else {
          // 中间的状态
          for (let i = currentPage.value - 2; i <= currentPage.value + 2; i++) {
            result.push(i);
          }
        }
      }
      return result;
    });
    // 控制上一页和下一页变化
    const changePage = (type) => {
      if (type === false) {
        // 上一页
        // 页面是第一页时，禁止点击操作
        if (currentPage.value === 1) return;
        if (currentPage.value > 1) {
          currentPage.value -= 1;
        }
      } else if (type === true) {
        // 下一页
        // 页面是最后页时，禁止点击操作
        if (currentPage.value === pages.value) return;
        if (currentPage.value < pages.value) {
          currentPage.value += 1;
        }
      } else {
        // 点击页码
        if (currentPage.value === type) return;
        currentPage.value = type;
      }
      emit("change-page", currentPage.value);
    };
    return { list, currentPage, pages, changePage };
  },
};
</script>
<style scoped lang="scss">
.pagination {
  width: 100%;
  display: flex;
  justify-content: right;
  .preview {
    width: 30px;
    height: 30px;
    padding: 5px 10px;
    margin-right: 10px;
    background: url("../assets/btn_news_page.png") center center no-repeat;
    background-size: contain;
    display: none;
  }
  .next {
    width: 30px;
    height: 30px;
    padding: 5px 10px;
    margin-right: 10px;
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg);
    background: url("../assets/btn_news_page.png") center center no-repeat;
    background-size: contain;
    display: none;
  }
  > a {
    width: 26px;
    height: 26px;
    font-size: 13px;
    color: #3b4355;
    line-height: 26px;
    margin-right: 10px;
    cursor: pointer;
    display: inline-block;
    &:hover {
      color: #20242f;
    }
    &.active {
      border: 1px solid #b10011;
      border-radius: 4px;
    }
    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
      &:hover {
        color: #333;
      }
    }
  }
  > span {
    font-size: 12px;
    line-height: 26px;
    font-weight: bolder;
    color: #3b4355;
    margin-right: 10px;
  }
}
</style>
