<template>
  <router-view />
</template>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
}

a:link {
  color: #ffffff;
  text-decoration: none;
}

a:visited {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  color: #ffffff;
  text-decoration: none;
}

a:active {
  color: #ffffff;
  text-decoration: none;
}

a:focus {
  outline: 0;
}

input:focus {
  outline: none;
}

input,
button,
select,
textarea {
  outline: none;
  background: transparent;
  border: none;
  resize: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

img {
  border: none;
  display: block;
  -webkit-touch-callout: inherit;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

article,
aside,
footer,
header,
hgroup,
nav,
section,
figure,
figcaption {
  display: block;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

.hover {
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.f_l {
  float: left;
}

.f_r {
  float: right;
}

.clear {
  clear: both;
}

html {
  width: 100%;
  min-width: 1000px;
  min-height: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: inherit;
  -webkit-user-select: none;
  overflow: visible;
}

body {
  width: 100%;
  min-width: 1000px;
  margin: 0 auto;
  padding: 0;
  overflow: visible;
}

#app {
  width: 100%;
  min-width: 1000px;
  margin-top: -1px;
  padding-top: 1px;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #323044;
  background-color: #f7f7f7;
}

.BMap_cpyCtrl {
  display: none !important;
}

.anchorBL {
  display: none !important;
}

.video-js .vjs-big-play-button {
  display: none !important;
}
</style>
