import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueScrollTo from "vue-scrollto";
import "animate.css/animate.min.css";
import BaiduMap from "vue-baidu-map-3x";

createApp(App)
  .use(store)
  .use(router)
  .use(BaiduMap, {
    ak: "SE365KM0EF5lREkgbESQIf5nW3YpV1Yp",
  })
  .use(VueScrollTo, {
    container: "body",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: false,
    y: true,
  })
  .mount("#app");
